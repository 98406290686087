import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import Toast, {POSITION} from "vue-toastification"
import "vue-toastification/dist/index.css"
import {vfmPlugin} from 'vue-final-modal'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Pagination from 'v-pagination-3'
import vClickOutside from "click-outside-vue3"

window.swal = require('sweetalert');
window.moment = require('moment');

import * as amplitude from '@amplitude/analytics-browser';
amplitude.init('32ed5b9105a8d7148eda28b557db3c29', {"autocapture":true});

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

// Styles
import '@vueform/slider/themes/default.css';
import '@vueform/multiselect/themes/default.css';

require('./assets/scss/theme/theme.scss');
require('./assets/scss/components/style.scss');

// window.API_BASE = 'http://syndesi-api.test/api';
// window.IMAGE_STORAGE_BASE = 'http://syndesi-api.test/storage';
// window.API_TOKEN_URL = 'http://syndesi-api.test/api/token';

window.API_BASE = 'https://api.syndesi.network/api';
window.IMAGE_STORAGE_BASE = 'https://api.syndesi.network/storage';
window.API_TOKEN_URL = 'https://api.syndesi.network/api/token'

if (window.location.hostname == 'localhost') {
    window.API_BASE = 'https://syndesi-api.test/api';
    window.IMAGE_STORAGE_BASE = 'https://sydnesi-api.test/storage';
    window.API_TOKEN_URL = 'https://syndesi-api.test/api/token';

    // window.API_BASE = 'https://api.testing.syndesi.network/api';
    // window.IMAGE_STORAGE_BASE = 'https://api.testing.syndesi.network/storage';
    // window.API_TOKEN_URL = 'https://api.testing.syndesi.network/api/token';

    // window.API_BASE = 'https://api.syndesi.network/api';
    // window.IMAGE_STORAGE_BASE = 'https://api.syndesi.network/storage';
    // window.API_TOKEN_URL = 'https://api.syndesi.network/api/token';
}

if (window.location.hostname == 'app.testing.syndesi.network') {
    window.API_BASE = 'https://api.testing.syndesi.network/api';
    window.IMAGE_STORAGE_BASE = 'https://api.testing.syndesi.network/storage';
    window.API_TOKEN_URL = 'https://api.testing.syndesi.network/api/token';
}

if (window.location.hostname == 'app-testing.syndesi.network') {
    window.API_BASE = 'https://api-testing.syndesi.network/api';
    window.IMAGE_STORAGE_BASE = 'https://api-testing.syndesi.network/storage';
    window.API_TOKEN_URL = 'https://api-testing.syndesi.network/api/token';
}

if (window.location.hostname == 'app.syndesi.network') {
    window.API_BASE = 'https://api.syndesi.network/api';
    window.IMAGE_STORAGE_BASE = 'https://api.syndesi.network/storage';
    window.API_TOKEN_URL = 'https://api.syndesi.network/api/token'
}

// Axios Interceptor
axios.interceptors.request.use(
    config => {
        const token = store.getters['auth/apiToken'];
        const userRole = store.getters['auth/userRole'];
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        if (userRole) {
            config.headers['UserRoleId'] = userRole.id;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);

// Axios Interceptor
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (
        error.response.status === 401
        && error.response.data.message === 'OTP code required'
        && router.currentRoute.value.path !== '/2fa'
    ) {
        router.push('/2fa');
    } else if (
        error.response.status === 401
    ) {
        router.push('/');
        store.dispatch('auth/logout');
    }
    return Promise.reject(error);
});

let app = createApp(App).use(store).use(Toast, {
    transition: 'Vue-Toastification__fade',
    position: POSITION.TOP_CENTER,
    timeout: 2500
}).use(router).use(vfmPlugin);

// app.use(VueGeolocation);
app.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDeB2tUw10SCOH45IiSkGtjzTmVtSycAhQ",
        libraries: ["places"],
    },
});

import FloatingVue from 'floating-vue'

app.use(FloatingVue)
import 'floating-vue/dist/style.css'

// Echo
import Echo from "laravel-echo"

window.Pusher = require('pusher-js');

// // Production
// let pusherKey = '6c551afbd48a1bd2fcc3';
//
// if (window.location.hostname == 'localhost') {
//     pusherKey = 'ddb3d9a827b1cd2ae975';
// }
//
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: pusherKey,
//     cluster: 'eu',
//     forceTLS: true
// });

const token = store.getters['auth/apiToken'];

if (window.location.hostname === 'localhost') {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'localkey',
        wsHost: window.location.hostname,
        wsPort: 6001,
        forceTLS: false,
        disableStats: true,
        authEndpoint: 'https://syndesi-api.test/api/broadcasting/auth',
        auth: {
            headers: {
                Authorization: "Bearer " + token
            }
        }
    });
    // window.Echo = new Echo({
    //     broadcaster: 'pusher',
    //     key: 'testingkey',
    //     wsHost: 'socket.syndesi.network',
    //     wsPort: 443,
    //     forceTLS: true,
    //     disableStats: true,
    //     authEndpoint: 'https://api-testing.syndesi.network/api/broadcasting/auth',
    //     auth: {
    //         headers: {
    //             Authorization: "Bearer " + token
    //         }
    //     }
    // });
} else if (window.location.hostname === 'app-testing.syndesi.network') {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'testingkey',
        wsHost: 'socket.syndesi.network',
        wsPort: 443,
        forceTLS: true,
        disableStats: true,
        authEndpoint: 'https://api-testing.syndesi.network/api/broadcasting/auth',
        auth: {
            headers: {
                Authorization: "Bearer " + token
            }
        }
    });
} else {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'productionkey',
        wsHost: 'socket.syndesi.network',
        wsPort: 443,
        forceTLS: true,
        disableStats: true,
        authEndpoint: 'https://api.syndesi.network/api/broadcasting/auth',
        auth: {
            headers: {
                Authorization: "Bearer " + token
            }
        }
    });
}

app.component('pagination', Pagination);

app.use(vClickOutside)
app.use(Vue3ColorPicker);

app.mount('#app');